
import ProductSearch from "../productsearch/ProductSearch";
import ProductSelectKeyword from "../productselect/ProductSelectKeyword";
import ReviewFilter from "../reviewfilter/ReviewFilter";
import MarketplaceSelection from "../marketplaceselection/MarketplaceSelection";
import DataTable from "./DataTable";
import 'boxicons';
import React, { Component, useState, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import { initializeApp } from "firebase/app";
import { arrayUnion, getFirestore, query, updateDoc } from "firebase/firestore";
import { collection, doc, getDoc, getDocs, where, setDoc, connectFirestoreEmulator } from "firebase/firestore"; 
import { getAuth, signInWithRedirect, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Box from '@mui/material/Box';
import { Tooltip } from 'react-tooltip'
import InfoIcon from '@mui/icons-material/Info';
import Typewriter from "typewriter-effect";
import Cookies from 'js-cookie'
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBgBYcGBgX4LRH23dP3hTg7Qv9xoSl938M",
  authDomain: "reviews-dd628.firebaseapp.com",
  databaseURL: "https://reviews-dd628-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "reviews-dd628",
  storageBucket: "reviews-dd628.appspot.com",
  messagingSenderId: "1076523572946",
  appId: "1:1076523572946:web:0fd3b378da56bf2873970b",
  measurementId: "G-TR82RMTEEZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

var outputstatement = "Statement"
const db = getFirestore(app)

const auth = getAuth(app);

class KeywordAnalysisTable extends Component{

    constructor(props){
        super(props);
        this.state = {loadingButton: false}
        this.state = {loadingButton2: false}
        this.state = {loadingData: true}
    }

    handleChangeButton = () => {
        this.setState({
          loadingButton: true
        })
        console.log(this.state.loadingButton)
      }

      handleChangeButton2 = () => {
        this.setState({
          loadingButton2: true
        })
        console.log(this.state.loadingButton)
      }

    handleChange = () => {
      this.setState({
        loadingData: false
      })
    }

    keywordFilter(){
        const formE13 = document.querySelector('.form3');
        
        formE13.addEventListener('submit',event => {
        this.handleChangeButton()
        event.preventDefault();
        const formData = new FormData(formE13);
        const data = Object.fromEntries(formData);

        fetch('https://flask-backend-7hpag3baeq-nw.a.run.app/test7',{
            method:'POST',
            'credentials': 'include',
            headers:{
            'Content-Type':'application/json',
            'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
            .then(window.location.reload())
            .then(data => console.log(data))
            .catch(error => console.log(error));
    });
    }

    generateKeywords = () => {
    
      this.handleChangeButton()
      fetch('https://flask-backend-7hpag3baeq-nw.a.run.app/test5',{
        method:'POST',
        'credentials': 'include',
        headers:{
          'Content-Type':'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify("productName")
      }).then(res => res.text())
        .then(data => alert("Keyword Generation Complete"))
        .then(() => {window.location.reload();})
        .then(data => console.log(data))
        //.then(window.location.reload())
        .catch(error => console.log(error));
  
  }

    productAdd(){
        const formE13 = document.querySelector('.form4');

        formE13.addEventListener('submit',event => {
            
        this.handleChangeButton2()
        event.preventDefault();
        const formData = new FormData(formE13);
        const data = Object.fromEntries(formData);

        fetch('https://flask-backend-7hpag3baeq-nw.a.run.app/reviewsummary',{
            method:'POST',
            'credentials': 'include',
            headers:{
            'Content-Type':'application/json',
            'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(data)
        }).then(res => res.text())
        .then(data => alert("Statement Generated"))
        .then(() => {window.location.reload();})
            .then(data => console.log(data))
            .catch(error => console.log(error));
    });
    }

    componentDidMount(){
        const dataFetch = async () => {
            
            
            
            const querySnapshot = doc(db, "reviewdata", Cookies.get('UIDValue'));
            const docSnap = await getDoc(querySnapshot);
            console.log(docSnap.data())
            
            if (docSnap.exists()) {
              
              outputstatement = docSnap.data().outputprompt
              console.log(outputstatement)
              document.getElementById('systemstatement').innerText = docSnap.data().outputprompt
              // <Typewriter
  
              // onInit={(typewriter)=> {
            
              // typewriter
               
              // .typeString("Generating Statement")
                
              // .pauseFor(3000)
              // .deleteAll()
              // .typeString(outputstatement)
              // .start();
              // }}
              // />
            } else {
              // doc.data() will be undefined in this case
              document.getElementById('systemstatement').innerHTML = "A statement could not be generated"
            }
            
        }
        dataFetch();

    }

    

    keywordAlphabeticalSort(){
        const formE14 = document.querySelector('.form4');

        formE14.addEventListener('submit',event => {
        event.preventDefault();
        
        var value = document.getElementById('keywordid').innerText;

        fetch('https://flask-backend-7hpag3baeq-nw.a.run.app/test8',{
            method:'POST',
            headers:{
            'Content-Type':'application/json',
            'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(value)
        }).then(res => res.json())
            .then(data => console.log(data))
            .then(window.location.reload())
            .catch(error => console.log(error));
        });
    }

    recordSort(){
        const formE15 = document.querySelector('.form5');

        formE15.addEventListener('submit',event => {
        event.preventDefault();
        
        var value = document.getElementById('keywordid1').innerText;

        fetch('https://flask-backend-7hpag3baeq-nw.a.run.app/test8',{
            method:'POST',
            headers:{
            'Content-Type':'application/json',
            'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(value)
        }).then(res => res.json())
            .then(data => console.log(data))
            .then(window.location.reload())
            .catch(error => console.log(error));
        });
    }

    positiveSort(){
        const formE16 = document.querySelector('.form6');

        formE16.addEventListener('submit',event => {
        event.preventDefault();
        
        var value = document.getElementById('keywordid2').innerText;

        fetch('https://flask-backend-7hpag3baeq-nw.a.run.app/test8',{
            method:'POST',
            headers:{
            'Content-Type':'application/json',
            'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(value)
        }).then(res => res.json())
            .then(data => console.log(data))
            .then(window.location.reload())
            .catch(error => console.log(error));
        });
    }

    negativeSort(){
        const formE17 = document.querySelector('.form7');

        formE17.addEventListener('submit',event => {
        event.preventDefault();
        
        var value = document.getElementById('keywordid3').innerText;

        fetch('https://flask-backend-7hpag3baeq-nw.a.run.app/test8',{
            method:'POST',
            headers:{
            'Content-Type':'application/json',
            'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(value)
        }).then(res => res.json())
            .then(data => console.log(data))
            .then(window.location.reload())
            .catch(error => console.log(error));
        });
    }

    avgRatingSort(){
        const formE18 = document.querySelector('.form8');

        formE18.addEventListener('submit',event => {
        event.preventDefault();
        
        var value = document.getElementById('keywordid4').innerText;

        fetch('https://flask-backend-7hpag3baeq-nw.a.run.app/test8',{
            method:'POST',
            headers:{
            'Content-Type':'application/json',
            'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(value)
        }).then(res => res.json())
            .then(data => console.log(data))
            .then(window.location.reload())
            .catch(error => console.log(error));
        });
    }


    render(){
      
        return (

            
            <div class="container">
            <div id="div-container">
           
              <ProductSearch/>
                
            
            
            <div class="widgets">
          
          
            
          
          
            <ProductSelectKeyword/>
            <ReviewFilter/>
            <MarketplaceSelection/>
            </div>
            <div class="widgets">
            <div class = 'widget'>
              
              <form class = 'form3'>
              <a data-tooltip-id="keywordsubmit-tooltip" data-tooltip-content="Keyword Submission - Enter a keyword here, and our system will scan the gathered reviews and generate information on the entered keyword."><InfoIcon/></a>
                <Tooltip id="keywordsubmit-tooltip"/>
                <input type="text" placeholder="Keyword" name="search" id = 'amazonlink'/>
                
                <Box sx={{ m: 1, position: 'relative' }}>
                <Button type="submit" variant="contained" disabled={this.state.loadingButton} id="submitButton1" onClick={() => {this.keywordFilter()}}endIcon={<SendIcon />}>
                Send
              </Button>
              {this.state.loadingButton && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: 'absolute',
              top: '50%',
              left: '25%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
              </form>
              <Box sx={{ m: 1, position: 'relative' }}>
            <Button variant="contained" disabled={this.state.loadingButton} id="submitButton1" onClick={() => {this.generateKeywords()}}endIcon={<SendIcon />}>
              Generate Keywords
            </Button>
            {this.state.loadingButton && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: 'absolute',
              top: '15%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
              
            </div>
            <div class = 'widget'>
              
              <form class = 'form4'>
              <a data-tooltip-id="statementsubmit-tooltip" data-tooltip-content="Statement Generator - Enter a product name/keyword, and a statement generated by OpenAI will be given. Note - maximum of 4 products supported, if an error occurs, please remove filters and try again."><InfoIcon/></a>
                <Tooltip id="statementsubmit-tooltip"/>
                <input type="text" placeholder="Product Name" name="search" id = 'amazonlink'/>
                <Box sx={{ m: 1, position: 'relative' }}>
                <Button type="submit" disabled={this.state.loadingButton2} variant="contained" id="submitButton1" onClick={() => {this.productAdd()}}endIcon={<SendIcon />}>
                Send
              </Button>
              {this.state.loadingButton2 && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: 'absolute',
              top: '50%',
              left: '20%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
              </form>
              
            </div>
            </div>
            <div class="statementWidget">
            
            <a data-tooltip-id="systemstatement-tooltip" data-tooltip-content="System Statement - A statement will be generated by OpenAI about your product. If no statement exists, please enter a keyword/product name into the field named 'Product Name'."><InfoIcon/></a>
            <Tooltip id="systemstatement-tooltip"/>    
                <p id="systemstatement">Statement</p>
            </div>
            <div class = "dataTableWidget">
              <DataTable/>
            </div>
            
          
    
            
        
        
        
      </div>
      </div>
        )
    }
}


export default KeywordAnalysisTable;