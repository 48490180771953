import "./chart.scss"
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line } from 'recharts';
import Cookies from 'js-cookie'
import { Component } from "react";
import { render } from "@testing-library/react";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithRedirect, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { arrayUnion, getFirestore, query, updateDoc } from "firebase/firestore";
import { collection, doc, getDoc, getDocs, where, setDoc, connectFirestoreEmulator } from "firebase/firestore"; 

const firebaseConfig = {
  apiKey: "AIzaSyBgBYcGBgX4LRH23dP3hTg7Qv9xoSl938M",
  authDomain: "reviews-dd628.firebaseapp.com",
  databaseURL: "https://reviews-dd628-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "reviews-dd628",
  storageBucket: "reviews-dd628.appspot.com",
  messagingSenderId: "1076523572946",
  appId: "1:1076523572946:web:0fd3b378da56bf2873970b",
  measurementId: "G-TR82RMTEEZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


const db = getFirestore(app)

const auth = getAuth(app);




const dataTemp = [
    {date: "January", rating: 4.5,},
    {date: "February", rating: 4.1,},
    {date: "March", rating: 4.3,},
    {date: "April", rating: 4.2,},
    {date: "May", rating: 4.6,},
    {date: "June", rating: 4.9,},
  ];

  const dataTempNames = [
    "Product1", "Product2", "Product3", "Product4", "Product5"
  ]

  
  

  const data2 = [
    {
      month: '2015.01',
      a: 4000,
      b: 2400,
      c: 2400,
    },
    {
      month: '2015.02',
      a: 3000,
      b: 1398,
      c: 2210,
    },
    {
      month: '2015.03',
      a: 2000,
      b: 9800,
      c: 2290,
    },
    {
      month: '2015.04',
      a: 2780,
      b: 3908,
      c: 2000,
    },
    {
      month: '2015.05',
      a: 1890,
      b: 4800,
      c: 2181,
    },
    {
      month: '2015.06',
      a: 2390,
      b: 3800,
      c: 2500,
    },
    {
      month: '2015.07',
      a: 3490,
      b: 4300,
      c: 2100,
    },
  ];

  const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;

  const getPercent = (value, total) => {
    const ratio = total > 0 ? value / total : 0;
  
    return toPercent(ratio, 2);
  };
  
  const renderTooltipContent = (o) => {
    const { payload, label } = o;
    const total = payload.reduce((result, entry) => result + entry.value, 0);
    return (
      <div className="customized-tooltip-content">
        <p className="total">{`${label} (Total: ${total})`}</p>
        <ul className="list">
          {payload.map((entry, index) => (
            <li key={`item-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: ${entry.value}(${getPercent(entry.value, total)})`}
            </li>
          ))}
        </ul>
      </div>
    );
  };
  
class ChartKeywordPositive extends Component{

state = {
  chartData: dataTemp,
  nameData: dataTempNames
}

  

  async componentDidMount(){
   const dataFetch = async () => {


    const promise = new Promise((res) => {
      res(fetch('https://flask-backend-7hpag3baeq-nw.a.run.app/mainindividualkeywordtablepositivecomments',{
        'credentials': 'include',
       })
      .then(function (response) {
             return response.json();
         }).then(function (text) {
            console.log('GET response:');
            console.log(text);
            const dataDB = text;
            window.dataDBKeywordPositive = dataDB;
            let D = {};
            Object.keys(text[0]).forEach(k => {
            D[k] = text.map(o => o[k]);
            });
            
            
          }));
    })
    const res = await promise;
      
        this.setState({
          chartData: window.dataDBKeywordPositive
          
        })
          
    }
     const dataFetch2 = async () => {


    //   const promise = new Promise((res) => {
    //     res(fetch('https://flask-backend-7hpag3baeq-nw.a.run.app/test3',{
    //       'credentials': 'include',
    //      })
    //     .then(function (response) {
    //            return response.json();
    //        }).then(function (text) {
    //           console.log('GET response:');
    //           console.log(text);
    //           const dataDBNames = text;
    //           window.dataDBNames = dataDBNames;
              
              
              
              
    //         }));
    //   })
    //   const res = await promise;
      
    //   this.setState({
    //     nameData: window.dataDBNames
        
    //   })
          
            
    //   }

    const querySnapshot = doc(db, "reviewdata", Cookies.get('UIDValue'));
    const docSnap = await getDoc(querySnapshot);
    console.log(docSnap.data())
    const graphNames = docSnap.data().specificproduct;

    if (docSnap.exists()) {
      this.setState({
        nameData: graphNames
      })
     
      
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }

    dataFetch2();
    
    dataFetch();
    console.log(window.dataDBKeywordPositive)
  }

  

  

  
render() {
  const { chartData } = this.state;
  const { nameData } = this.state;
  console.log(nameData)
  console.log(chartData)
    return (
        
        
        <div className="title">Total Number of Positive Comments
        
        <LineChart
          width={950}
          height={460}
          data={chartData}
          stackOffset="expand"
          margin={{
            top: 10,
            right: 30,
            left: 40,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis domain={[0, 10]} allowDataOverflow={true}/>
          <Tooltip />
          <Line type="monotone" dataKey={nameData[0]} stackId="1" strokeWidth={2} stroke="#8884d8" fill="#8884d8" />
          <Line type="monotone" dataKey={nameData[1]} stackId="1" strokeWidth={2} stroke="#82ca9d" fill="#82ca9d" />
          <Line type="monotone" dataKey={nameData[2]} stackId="1" strokeWidth={2} stroke="#ffc658" fill="#ffc658" />
          <Line type="monotone" dataKey={nameData[3]} stackId="1" strokeWidth={2} stroke="#CB3234" fill="#CB3234" />
          <Line type="monotone" dataKey={nameData[4]} stackId="1" strokeWidth={2} stroke="#F3A505" fill="#F3A505" />
          
        </LineChart>
        
        </div>
    )
};
}




export default ChartKeywordPositive;