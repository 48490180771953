import 'boxicons';
import React, { Component, useState, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import { initializeApp } from "firebase/app";
import { arrayUnion, getFirestore, query, updateDoc } from "firebase/firestore";
import { collection, doc, getDoc, getDocs, where, setDoc, connectFirestoreEmulator } from "firebase/firestore"; 
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Box from '@mui/material/Box';
import { Tooltip } from 'react-tooltip'
import InfoIcon from '@mui/icons-material/Info';
import Cookies from 'js-cookie'
const firebaseConfig = {
  apiKey: "AIzaSyBgBYcGBgX4LRH23dP3hTg7Qv9xoSl938M",
  authDomain: "reviews-dd628.firebaseapp.com",
  databaseURL: "https://reviews-dd628-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "reviews-dd628",
  storageBucket: "reviews-dd628.appspot.com",
  messagingSenderId: "1076523572946",
  appId: "1:1076523572946:web:0fd3b378da56bf2873970b",
  measurementId: "G-TR82RMTEEZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


const db = getFirestore(app)


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

var names = [
  'Temporary Values'
];
export default function ProductSelectKeyword() {

  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [productName, setProductName] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingButton, setLoadingButton] = useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setProductName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    
  };
  
  const handleChangeButton = () => {
    setLoadingButton(true);
    console.log(isLoadingButton)
  }

  const dataFetch = async () => {
    // const promise = new Promise((res) => {
    //   res(fetch('https://flask-backend-7hpag3baeq-nw.a.run.app/test3')
    //   .then(function (response) {
    //       return response.json();
    //   }).then(function (text) {
    //       console.log('GET response:');
    //       console.log(text);
    //       names = text
    //       console.log(names)
    //       setLoading(false)
            
    //     }));
          
        
    //   })
    // }
    const querySnapshot = doc(db, "reviewdata", Cookies.get('UIDValue'));
    const docSnap = await getDoc(querySnapshot);
    console.log(docSnap.data())

    if (docSnap.exists()) {
      names = docSnap.data().productnames;
      setLoading(false)
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
}

    useEffect(() => {
      dataFetch();
    }, []);


    const selectProduct = async () => {
    

      // fetch('https://flask-backend-7hpag3baeq-nw.a.run.app/test2',{
      //   method:'POST',
      //   headers:{
      //     'Content-Type':'application/json',
      //     'Access-Control-Allow-Origin': '*'
      //   },
      //   body: JSON.stringify(productName)
      // }).then(res => res.json())
      //   .then(data => console.log(data))
      //   .then(window.location.reload())
      //   .catch(error => console.log(error));
    handleChangeButton();
    const selectProductRef = doc(db, "reviewdata", Cookies.get('UIDValue'));
    await updateDoc(selectProductRef, {
      specificproduct: productName
    }, {merge: true}).then(() => {window.location.reload();})
  }

  const generateKeywords = async () => {
    
    handleChangeButton();
    fetch('https://flask-backend-7hpag3baeq-nw.a.run.app/test5',{
      method:'POST',
      'credentials': 'include',
      headers:{
        'Content-Type':'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(productName)
    }).then(res => res.json())
      
      .then(data => console.log(data))
      .then(window.location.reload())
      .catch(error => console.log(error));

}

 
  if (isLoading) {
    return <div className="Loading">Loading... This may take some time.</div>
  }


    return (
        <div id = "productselect">
        <div class = 'widget'>
        <a data-tooltip-id="productselect-tooltip" data-tooltip-content="Product Select - Select from the products you have searched for, and generate keywords for the products selected. You can also generate keyword for the table below."><InfoIcon/></a>
        <Tooltip id="productselect-tooltip"/>
        <FormControl id="marketplacefilter" sx={{ m: 1, width: 200 }}>
              <InputLabel id="marketplace-filter-type">Select Product</InputLabel>
              <Select
                labelId="marketplace-filter-type"
                id="marketplace-filter-type"
                multiple
                value={productName}
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {names.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={productName.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
                
              </Select>
            </FormControl>
            <div id="submitButton1">
            <Stack direction="column" spacing={1}>
            <Box sx={{ m: 1, position: 'relative' }}> 
            <Button variant="contained" style={{maxHeight: '53px', minHeight: '53px'}} disabled={isLoadingButton} id="submitButton1" onClick={() => {selectProduct()}}endIcon={<SendIcon />}>
              Send
            </Button>
            {isLoadingButton && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
      
            </Stack>
            </div>
            
            
          </div>
    </div> 
    )




}



