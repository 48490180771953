import "./settings.scss"
import { CircularProgressbar } from "react-circular-progressbar"
import "../../../node_modules/materialize-css/sass/materialize.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import { Button } from "@mui/material"
import React, {useState, useEffect, useMemo, useRef} from 'react';

import { initializeApp } from "firebase/app";
import { arrayUnion, getFirestore, query, updateDoc } from "firebase/firestore";
import { collection, doc, getDoc, getDocs, where, setDoc, connectFirestoreEmulator } from "firebase/firestore"; 
import { Tooltip } from 'react-tooltip'
import InfoIcon from '@mui/icons-material/Info';
import { getFunctions, httpsCallable} from 'firebase/functions'
import { getAuth, signInWithRedirect, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Box from '@mui/material/Box';
import { useForm, ValidationError } from '@formspree/react';
import Cookies from 'js-cookie'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBgBYcGBgX4LRH23dP3hTg7Qv9xoSl938M",
  authDomain: "reviews-dd628.firebaseapp.com",
  databaseURL: "https://reviews-dd628-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "reviews-dd628",
  storageBucket: "reviews-dd628.appspot.com",
  messagingSenderId: "1076523572946",
  appId: "1:1076523572946:web:0fd3b378da56bf2873970b",
  measurementId: "G-TR82RMTEEZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const functions = getFunctions(app, "europe-west2")
var userID = 'a'
var reviewquota = 0
var statementquota = 0
var reviewcap = 0
var statementcap = 0







const signInGoogle = () => {
    const userCredentials = signInWithRedirect(auth, provider);
}

const signOutButton = () => {
    auth.signOut();
    alert("Signed Out");
}




const Settings = () => {
    const [isLoading, setLoading] = useState(true);
    const [isLoadingButton, setLoadingButton] = useState(false);
    const [state, handleSubmit] = useForm("xqkobwaz");
    if (state.succeeded) {
        return <p>Thanks for joining!</p>;
    }

    const handleChangeButton = () => {
        setLoadingButton(true);
        console.log(isLoadingButton)
      }

    const stripeLink = async () => {
        handleChangeButton()
        console.log(functions)
        const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
        console.log("Link")
        const {data} = await functionRef({ returnUrl: window.location.origin});
        
        window.location.assign(data.url)
    
    }
    
    onAuthStateChanged(auth, async (user) => {
        if (!user){
            //window.location.assign("https://youtube.com")
            //alert("Not logged in")
            setLoading(false);
        }
        else{
            //console.log(user.uid)
            userID = user.uid
            const quotaRef = doc(db, "reviewdata", Cookies.get('UIDValue'))
            const docSnap = await getDoc(quotaRef)
            if (docSnap.exists()) {
                //console.log("Document data:", docSnap.data());
                reviewquota = docSnap.data().reviewcount
                statementquota = docSnap.data().statementcount
                reviewcap = docSnap.data().reviewlimit
                statementcap = docSnap.data().statementlimit
                setLoading(false);
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
    }
        }
    });

    if (isLoading) {
        return <div className="Loading">Loading... This may take some time.</div>
      }
    return (
        
        <div className="list">
            <Sidebar/>
            
            <div className="listContainer">
            <Navbar/>
            
        <div id="div-container">

          

          

             
          
            
            <div id="rootCompare">
            
          
                
                <div class="container">
                <div id="div-container">
                    <div class="widgets">
                        
                            <div class = "widget">
                                <div className="top">
                                    <h1 class="settingsTitle">Review Quota</h1>
                                </div>
                                <div className="bottom">
                                    <CircularProgressbar value={reviewquota/1000} text={String(((reviewquota/reviewcap)*100).toFixed(2)).concat("%")} strokeWidth={5}></CircularProgressbar>
                                    <p>You have used {reviewquota} reviews, out of the {reviewcap} in the quota</p>
                                </div>
                            </div>
                            <div class = "widget">
                                <div className="top">
                                    <h1 class="settingsTitle">Statement Quota</h1>
                                </div>
                                <div className="bottom">
                                    <CircularProgressbar value={statementquota/1000} text={String(((statementquota/statementcap)*100).toFixed(2)).concat("%")} strokeWidth={5}></CircularProgressbar>
                                    <p>You have used {statementquota} statements, out of the {statementcap} in the quota</p>
                                </div>
                            </div>
                            </div>
                            <div class = "widgets">
                            <div class = "widget">
                            <div className="top">
                                <h1 class = "settingsTitle">Stripe Portal</h1>
                            </div>
                            <div className="bottom">
                                <p>Click the button below to open the Stripe customer portal. From here, you can change plans and manage payment details.</p>
                                <Box sx={{ m: 1, position: 'relative' }}>
                                <Button variant="contained" onClick={() => {stripeLink()}}>Stripe Portal</Button>
                                {isLoadingButton && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                    }}
                                />
                                )}
                            </Box>
                            </div>
                            </div>
                            {/* <div class = "widget">
                                <h1 class = "settingsTitle">Login Button Temp</h1>
                                
                                <Button variant="contained" onClick={() => {signInGoogle()}}>Google Sign In</Button>
                            </div>
                            <div class = "widget">
                                <h1 class = "settingsTitle">Sign Out Button Temp</h1>
                                
                                <Button variant="contained" onClick={() => {signOutButton()}}>Google Sign In</Button>
                            </div> */}
                            </div>
                            <div class="widgets">
                            <div class = "contactWidget">
                            
                            <form id="fs-frm" name="simple-contact-form" accept-charset="utf-8" action="https://formspree.io/f/xqkobwaz" method="post">
                            <fieldset id="fs-frm-inputs">
                                <label for="full-name">Full Name</label>
                                <input type="text" name="name" id="full-name" placeholder="First and Last Name" required=""/>
                                <label for="email-address">Email Address</label>
                                <input type="email" name="_replyto" id="email-address" placeholder="email@emaildomain.com" required=""/>
                                <label for="message">Message</label>
                                <textarea rows="5" name="message" id="message" placeholder="Please enter your message here."  required=""></textarea>
                                <input type="hidden" name="_subject" id="email-subject" value="Contact Form Submission"/>
                            </fieldset>
                            <input type="submit" value="Submit"/>
                            </form>
                            </div>
                            </div>
                    
                </div>
                </div>
                
                
                </div>
                </div>
                
                
            </div>
        </div>
    )
}

export default Settings