import 'boxicons';
import { Component, useRef } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { ExportToCsv } from 'export-to-csv';
import { Tooltip } from 'react-tooltip'
import InfoIcon from '@mui/icons-material/Info';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Box from '@mui/material/Box';
class ProductSearch extends Component {
  
  
  constructor(props) {
    super(props);
    this.state = {country: ''}
    this.state = {loadingOverlay: false}
    this.state = {loadingButton: false}
    
}

  

  handleChange = (event) => {
    this.setState({
      country: (event.target.value)
      
    })
    console.log(this.state.country)
    
  }

  handleChangeButton = () => {
    this.setState({
      loadingButton: true
    })
    console.log(this.state.loadingButton)
  }

  searchProduct = () => {
    const formE1 = document.querySelector('.form');

    formE1.addEventListener('submit',event => {
      this.handleChangeButton()
      console.log("test1")
      event.preventDefault();
      const formData = new FormData(formE1);
      const data = Object.fromEntries(formData);
      data.country = this.state.country;
      console.log(data)
      // document.getElementById("overlayLoading").style.display = "block";
      // document.getElementsByClassName("loader")[0].style.display = "block";
      document.getElementById("amazonlink").disabled = true;
      
      

      fetch('https://flask-backend-7hpag3baeq-nw.a.run.app',{
        method:'POST',
        'credentials': 'include',
        headers:{
          'Content-Type':'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(data)
      }).then(res => res.text())
        .then(data => alert(data))
        .then(() => {window.location.reload();})
        .then(data => document.getElementById("amazonlink").disabled = false)
        .then(data => console.log(data))
        .catch(error => console.log(error));
        
    });
      }

    saveCSV = () =>  {
      var data = [
        {
          name: 'Test 1',
          age: 13,
          average: 8.2,
          approved: true,
          description: "using 'Content here, content here' "
        },
        {
          name: 'Test 2',
          age: 11,
          average: 8.2,
          approved: true,
          description: "using 'Content here, content here' "
        },
        {
          name: 'Test 4',
          age: 10,
          average: 8.2,
          approved: true,
          description: "using 'Content here, content here' "
        },
      ];
      alert("This feature is currently in development, and will be coming soon")
      
    }

  
  render() {
    return (
      <div id = "productsearch">
      <div class = 'widget'>
      <a data-tooltip-id="search-tooltip" data-tooltip-content="Search - Enter an ASIN number and the marketplace of the product."><InfoIcon/></a>
      <Tooltip id="search-tooltip"/>
      <FormControl sx={{minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label">Country</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={this.state.country || ''}
          label="Age"
          onChange={this.handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={"UK"}>UK</MenuItem>
          <MenuItem value={"USA"}>USA</MenuItem>
          <MenuItem value={"India"}>India</MenuItem>
          <MenuItem value={"Mexico"}>Mexico</MenuItem>
          <MenuItem value={"Germany"}>Germany</MenuItem>
          <MenuItem value={"France"}>France</MenuItem>
          <MenuItem value={"Italy"}>Italy</MenuItem>
          <MenuItem value={"Spain"}>Spain</MenuItem>
          <MenuItem value={"Japan"}>Japan</MenuItem>
          <MenuItem value={"Singapore"}>Singapore</MenuItem>
          <MenuItem value={"UAE"}>United Arab Emirates</MenuItem>
          <MenuItem value={"Brazil"}>Brazil</MenuItem>
          <MenuItem value={"Australia"}>Australia</MenuItem>
          <MenuItem value={"Netherlands"}>Netherlands</MenuItem>
          <MenuItem value={"Saudi Arabia"}>Saudi Arabia</MenuItem>
          <MenuItem value={"Turkey"}>Turkey</MenuItem>
          <MenuItem value={"Sweden"}>Sweden</MenuItem>
          <MenuItem value={"Poland"}>Poland</MenuItem>
          <MenuItem value={"Belgium"}>Belgium</MenuItem>
          
        </Select>
        <FormHelperText>Amazon Region</FormHelperText>
      </FormControl>
      <form class = 'form' id="sampleform">
      <box-icon name='search-alt'></box-icon>
        <input type="text" placeholder="Product ASIN" name="search" id = 'amazonlink'/>
        <Box sx={{ m: 1, position: 'relative' }}>
        <Button type="submit" disabled={this.state.loadingButton} style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} variant="contained" id="submitButton1" onClick={() => {this.searchProduct()}}endIcon={<SendIcon />}>
              Send
        </Button>
        {this.state.loadingButton && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
      </form>
      <Button type="submit" style={{maxWidth: '180px', maxHeight: '45px', minWidth: '30px', minHeight: '30px'}} sx={{ marginLeft: "auto" }} variant="contained" id="csvButton" onClick={() => {this.saveCSV()}}>
              Save to CSV
      </Button>

    
    </div>
    <div id="overlayLoading">
    <div class="loader">
      <div id="textLoading">Loading...</div>
      <div class="loading">
        
      </div>
    </div>
  </div>
  </div> 
  )
  }
}


export default ProductSearch;