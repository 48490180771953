import {ReactComponent as Logo} from "./logo.svg"
import LogoPNG from "./logopng.png"
import "./navbar.scss"
const Navbar = () => {
    return (
        <header>
        <div className="navbar">
            <nav class="nav-wraper">
    <nav class="nav-extended">

  <div class="nav-wrapper container">
    <a href="#" class="brand-logo"><img src={LogoPNG}/></a>
    <ul class="right">
      
    </ul>
    
    <ul class="right hide-on-med-and-down">
      
      
    </ul>
    
  </div>
</nav>
</nav>
        </div>
        </header>
    )
}

export default Navbar