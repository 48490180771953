import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Compare from "./pages/compare/Compare";
import Single from "./pages/single/Single";
import Keyword from "./pages/keyword/Keyword";

import { BrowserRouter, Routes, Route, useRoutes} from "react-router-dom";
import Settings from "./pages/settings/Settings";

  // const routesPath = ([
  //   { path: "/", 
  //     element: (alert("test"))
  //   },
  //   {
  //     path: "dashboard", 
  //     element: <Home />,
  //     children: [
  //       {path: "/dashboard/keyword", element: <Keyword/>, children: [{path: "individual", element: <Single/>}]},
  //       {path: "compare", element: <Compare/>},
  //     ],
  //   },
    
  // ]);

  // function Routing() {
  //   return useRoutes(routesPath);
  // }

export default function App() {

  

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
         
          <Route path="/">
            <Route index element={<Home/>}/>
            <Route path="login" element={<Login/>}/>
            <Route path="compare">
              <Route index element={<Compare/>}/>
              <Route path=":userId" element={<Single/>}/>
              
            </Route>
            <Route path="keyword">
              <Route index element={<Keyword/>}/>
              <Route path="individual" element={<Single/>}/>
              
            </Route>
            <Route path="settings" element={<Settings/>}>

            </Route>
          </Route>
        </Routes>
        {/* <Routing/> */}
      </BrowserRouter>
    </div>
  );
}


