

export const dataTableColumns = [
    { 
        field: 'id', 
        headerName: 'ID', 
        width: 90 },
    {
        field: 'keyword',
        headerName: 'Keyword',
        width: 150,
        editable: true,
    },
    {
        field: 'mentions',
        headerName: 'Mentions',
        width: 150,
        editable: true,
    },
    {
        field: 'averagerating',
        headerName: 'Average Rating',
        width: 150,
        editable: true,
    },
    {
        field: 'positive',
        headerName: 'Positive',
        width: 150,
        editable: true,
    },
    {
        field: 'negative',
        headerName: 'Negative',
        width: 150,
        editable: true,
    },
]

