import 'boxicons';
import React, { Component, useState, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import { initializeApp } from "firebase/app";
import { arrayUnion, getFirestore, query, updateDoc } from "firebase/firestore";
import { collection, doc, getDoc, getDocs, where, setDoc } from "firebase/firestore"; 
import { Tooltip } from 'react-tooltip'
import InfoIcon from '@mui/icons-material/Info';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Cookies from 'js-cookie'
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBgBYcGBgX4LRH23dP3hTg7Qv9xoSl938M",
  authDomain: "reviews-dd628.firebaseapp.com",
  databaseURL: "https://reviews-dd628-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "reviews-dd628",
  storageBucket: "reviews-dd628.appspot.com",
  messagingSenderId: "1076523572946",
  appId: "1:1076523572946:web:0fd3b378da56bf2873970b",
  measurementId: "G-TR82RMTEEZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  
  {name: 'Vine', value: "vine"},
  {name: 'Verified Purchase', value: "Verified Purchase"},
  {name: 'Non-verified Purchase', value: "Non-Verified"}
];


export default function ReviewFilter() {


   const [filterName, setFilterName] = useState([]);
   const [isLoadingButton, setLoadingButton] = useState(false);

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setFilterName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
      
    };

    const handleChangeButton = () => {
      setLoadingButton(true);
      console.log(isLoadingButton)
    }

  const selectFilter = async () => {
    

    //   fetch('https://flask-backend-7hpag3baeq-nw.a.run.app/vine',{
    //     method:'POST',
    //     headers:{
    //       'Content-Type':'application/json',
    //       'Access-Control-Allow-Origin': '*'
    //     },
    //     body: JSON.stringify(filterName)
    //   }).then(res => res.json())
    //     .then(data => console.log(data))
        
    //     .catch(error => console.log(error));
    // ;
    handleChangeButton();
    const selectProductRef = doc(db, "reviewdata", Cookies.get('UIDValue'));
    await updateDoc(selectProductRef, {
      vine: filterName
    }, {merge: true}).then(() => {window.location.reload();})
  }
  

     return (
        <div id = "productselect">
        <div class = 'filterWidget'>
        <a data-tooltip-id="reviewfilter-tooltip" data-tooltip-content="Review Filter - Choose a filter for the type of reviews that will be looked at by the system."><InfoIcon/></a>
        <Tooltip id="reviewfilter-tooltip"/>
            <FormControl id="reviewtypefilter" sx={{ m: 1, width: 250 }}>
              <InputLabel id="review-filter-type">Select Review Filter</InputLabel>
              <Select
                labelId="review-filter-type"
                id="review-filter-type"
                multiple
                value={filterName}
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {names.map((name, i) => (
                  <MenuItem key={i} value={name.value}>
                    <Checkbox checked={filterName.indexOf(name.value) > -1} />
                    <ListItemText primary={name.name} />
                  </MenuItem>
                ))}
                
              </Select>
            </FormControl>
            
            <div id="submitButton1">
            <Stack direction="column" spacing={1}>
            <Box sx={{ m: 1, position: 'relative' }}> 
            <Button variant="contained" style={{maxHeight: '53px', minHeight: '53px'}} disabled={isLoadingButton} id="submitButton1" onClick={() => {selectFilter()}}endIcon={<SendIcon />}>
              Send
            </Button> 
            {isLoadingButton && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
            </Stack>
            </div>

            
            
            
          </div>
    </div> 
    )
  }


   








