import React, {useState, useEffect, useMemo, useRef} from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import "../../components/keywordanalysis/dataTable.scss"
import { singledataTableColumns } from "./singledataTableSource";
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
import InfoIcon from '@mui/icons-material/Info';

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];






const SingleDataTable = () => {
  

  
  const [isLoading, setLoading] = useState(true);
  


  const dataFetch = () => {
    const promise = new Promise((res) => {
      res(fetch('https://flask-backend-7hpag3baeq-nw.a.run.app/mainindividualkeywordtable',{
        'credentials': 'include',
       })
      .then(function (response) {
          return response.json();
      }).then(function (text) {
          console.log('GET response:');
          console.log(text);
          const dataDBIndividual = text;
          window.dataDBIndividual = dataDBIndividual;
          
            setLoading(false);
          
      }));
    })

  }


  

  useEffect(() => {
    dataFetch();
  }, []);


    if (isLoading) {
      return <div className="Loading">Loading... This may take some time.</div>
    }

    return (
      
      <div className="dataTable">
        <a data-tooltip-id="individualdata-tooltip" data-tooltip-content="Individual Keyword Table - Look at all the reviews containing the keyword you selected."><InfoIcon/></a>
        <Tooltip id="individualdata-tooltip"/>
        <DataGrid
        
        rows={window.dataDBIndividual}
        columns={singledataTableColumns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        
        >
      </DataGrid>
      
      
    </div>
        
    );
}



export default SingleDataTable;