import React, { useState, Component, useEffect } from "react";
import { Header, Grid, Item, Table, Label } from "semantic-ui-react";
import ProductCard from "./ProductCard";

const style = {
  h1: {
    marginTop: "3em"
  },
  h2: {
    margin: "4em 0em 2em"
  },
  h3: {
    marginTop: "2em",
    padding: "2em 0em"
  },
  last: {
    marginBottom: "300px"
  }
};




  


const ProductComparison = ({ products }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setLoading] = useState(true);
  var comparedata = [];
  

  const dataFetch = () => {
    const promise = new Promise((res) => {
      res(fetch('https://flask-backend-7hpag3baeq-nw.a.run.app/comparepage',{
        'credentials': 'include',
       })
      .then(function (response) {
             return response.json();
         }).then(function (text) {
            console.log('GET response:');
            console.log(text);
            const dataDB = text;
            //window.dataDBCompare = dataDB;
            
            for (var i =0; i < (dataDB).length; i++){
              //console.log(Object.values(window.dataDBCompare[i])[1])
              comparedata.push(Object.values(dataDB[i])[1])
            }
            window.dataDBCompare = comparedata;
            
            
            //document.getElementsByClassName('resultAmount1Year')[0].innerHTML = ((Object.values(text[0]))[1]);
            setLoading(false);
            
            }));
    })

  }

  useEffect(() => {
    dataFetch();
    console.log(window.dataDBCompare)
  }, []);
  

  const addToCompare = (item) => {
    setSelectedItems((selectedItems) => [...selectedItems, item]);
  };

  const removeFromCompare = (item) => {
    const filteredItems = selectedItems.filter(
      (product) => product.id !== item.id
    );
    setSelectedItems((selectedItems) => filteredItems);
  };

  

  if (isLoading) {
    return <div className="Loading">Loading... This may take some time.</div>
  }

  
  //console.log(products)
            
  return (
    
    <div>
      
      {selectedItems.length > 0 && (
        <Table definition>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              {selectedItems.map((el) => (
                <Table.HeaderCell key={el.id}>{el.name}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Label color="orange" ribbon>
                  Ranking
                </Label>
              </Table.Cell>
              {selectedItems.map((el) => (
                <Table.Cell key={el.id}>{el.ranking}</Table.Cell>
              ))}
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Label color="orange" ribbon>
                  Average Rating
                </Label>
              </Table.Cell>
              {selectedItems.map((el) => (
                <Table.Cell key={el.id}>{el.average_rating}</Table.Cell>
              ))}
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Label color="teal" ribbon>
                  Total Reviews
                </Label>
              </Table.Cell>
              {selectedItems.map((el) => (
                <Table.Cell key={el.id}>{el.total_reviews}</Table.Cell>
              ))}
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Label color="teal" ribbon>
                  Positive Comments
                </Label>
              </Table.Cell>
              {selectedItems.map((el) => (
                <Table.Cell key={el.id}>{el.positive_comments}</Table.Cell>
              ))}
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Label color="pink" ribbon>
                Negative Comments
                </Label>
              </Table.Cell>
              {selectedItems.map((el) => (
                <Table.Cell key={el.id}>{el.negative_comments}</Table.Cell>
              ))}
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Label color="pink" ribbon>
                Non-verified Purchases
                </Label>
              </Table.Cell>
              {selectedItems.map((el) => (
                <Table.Cell key={el.id}>{el.non_verified_purchases}</Table.Cell>
              ))}
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Label color="pink" ribbon>
                Verified Purchases
                </Label>
              </Table.Cell>
              {selectedItems.map((el) => (
                <Table.Cell key={el.id}>{el.verified_purchases}</Table.Cell>
              ))}
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Label color="pink" ribbon>
                Vine Reviews
                </Label>
              </Table.Cell>
              {selectedItems.map((el) => (
                <Table.Cell key={el.id}>{el.vine_reviews}</Table.Cell>
              ))}
            </Table.Row>
          </Table.Body>
        </Table>
      )}
      <Grid columns={selectedItems.length} stackable padded divided>
        <Item.Group>
          {window.dataDBCompare.map((product) => (
            
            <ProductCard
              key={product.id}
              product={product}
              selected={selectedItems}
              addToCompare={addToCompare}
              removeFromCompare={removeFromCompare}
            />
          ))}
        </Item.Group>
      </Grid>
    </div>

    
  );

  
};



export default ProductComparison;
