
import { render } from '@testing-library/react';
import 'boxicons';
import { Component } from 'react';
import "./sidebar.scss"


class Sidebar extends Component{

sidebarSetup(){




    let btn = document.querySelector("#sidenav_btn");
    let sidebar = document.querySelector(".sidebar");
    //let searchBtn = document.querySelector(".menu");
    let logoutBtn = document.querySelector("#logout_btn");
    
    btn.onclick = function() {
      sidebar.classList.toggle("active");
    }

    logoutBtn.onclick = function() {
      
      alert("Signed Out")
    } 

    

    


}

componentDidMount(){
    this.sidebarSetup();
}

render() {
    return (
        <div class="sidebar">
        <div class="logo_content">
          <div class="logo">
            <i class='bx bxl-amazon'></i>
            <div class="logo_name">ReviewScout</div>
          </div>
          <box-icon name='menu' color="white" id="sidenav_btn"></box-icon>
        </div>
        <ul class="nav_list">
          
          <li>
            <a href="/">
                <box-icon name='grid-alt' type='solid' color="white"></box-icon>
              <span class="link_name">Dashboard</span>
            </a>
            <span class="tooltip">Dashboard</span>
          </li>
          <li>
            <a  href="/keyword" target="_blank">
            <box-icon name='bullseye' type='solid' color="white"></box-icon>
              <span class="link_name">Keyword Analysis</span>
            </a>
            <span class="tooltip-long">Keyword Analysis</span>
          </li>
          <li>
            <a href="/compare">
            <box-icon name='bar-chart' color="white"></box-icon>
              <span class="link_name">Compare</span>
            </a>
            <span class="tooltip">Compare</span>
          </li>
          
          <li>
            <a href="/settings">
            <box-icon name='cog' type='solid' color="white"></box-icon>
              <span class="link_name">Settings</span>
            </a>
            <span class="tooltip">Settings</span>
          </li>
        </ul>
        <div class="profile_content">
          <div class="profile">
            <div class="profile_details">
              <div class="nameandbusiness">
                <div id="profile_name" class="profile_name"></div>
                <div id="profile_business" class="profile_business"></div>
              </div>
            </div>
            <box-icon name='log-out-circle' type='solid' color="white" id="logout_btn"></box-icon>
          </div>
        </div>
      </div>
    );
};
}
export default Sidebar;