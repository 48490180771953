import React, {useState, useEffect, useMemo, useRef} from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import "./dataTable.scss"
import { dataTableColumns } from "./dataTableSource";
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
import InfoIcon from '@mui/icons-material/Info';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithRedirect, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { arrayUnion, getFirestore, query, updateDoc } from "firebase/firestore";
import { collection, doc, getDoc, getDocs, where, setDoc, connectFirestoreEmulator } from "firebase/firestore"; 
import Cookies from 'js-cookie'
const firebaseConfig = {
  apiKey: "AIzaSyBgBYcGBgX4LRH23dP3hTg7Qv9xoSl938M",
  authDomain: "reviews-dd628.firebaseapp.com",
  databaseURL: "https://reviews-dd628-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "reviews-dd628",
  storageBucket: "reviews-dd628.appspot.com",
  messagingSenderId: "1076523572946",
  appId: "1:1076523572946:web:0fd3b378da56bf2873970b",
  measurementId: "G-TR82RMTEEZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


const db = getFirestore(app)

const auth = getAuth(app);

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];






const DataTable = () => {
  

  const actionColumn = [{field: "action", headerName: "Action", width: 200, renderCell:(params)=>{
    return(
        <div className="actionCell">
          
          <div className="viewButton" onClick={() => {individualKeyword(params.row.keyword)}}>View</div>
          
          
        </div>
  )}}]
  const [isLoading, setLoading] = useState(true);
  


  const dataFetch = () => {
    const promise = new Promise((res) => {
      res(fetch('https://flask-backend-7hpag3baeq-nw.a.run.app/test6',{
        'credentials': 'include',
       })
      .then(function (response) {
          return response.json();
      }).then(function (text) {
          console.log('GET response:');
          console.log(text);
          const dataDBKeywords = text;
          window.dataDBKeywords = dataDBKeywords;
          
            setLoading(false);
          
      }));
    })

  }

  const individualKeyword = async (keywordValue) => {
    
        const individualkeywordRef = doc(db, "reviewdata", Cookies.get('UIDValue'));
        await updateDoc(individualkeywordRef, {
          specifickeyword: keywordValue
        }, {merge: true})
        fetch('https://flask-backend-7hpag3baeq-nw.a.run.app/individualkeywordtable',{
          method:'POST',
          'credentials': 'include',
          headers:{
            'Content-Type':'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          body: JSON.stringify(keywordValue),
          
        }).then(res => res.text())
          .then(data => console.log(data))
          .then(() => window.location.assign("https://dashboard.reviewscout.ai/keyword/individual"))
          .catch(error => console.log(error));
      
    }
  

  useEffect(() => {
    dataFetch();
  }, []);


    if (isLoading) {
      return <div className="Loading">Loading... This may take some time.</div>
    }

    return (
      
      <div className="dataTable">
        <a data-tooltip-id="datatable-tooltip" data-tooltip-content="Keyword Table - See the keywords associated with the product. If you see no data, please click the generate keywords button above. If you have already done so, please refresh the page."><InfoIcon/></a>
      <Tooltip id="datatable-tooltip"/>
        <DataGrid
        
        rows={window.dataDBKeywords}
        columns={dataTableColumns.concat(actionColumn)}
        pageSize={5}
        rowsPerPageOptions={[5]}
        
        >
      </DataGrid>
      
      
    </div>
        
    );
}



export default DataTable