

export const singledataTableColumns = [
    { 
        field: 'id', 
        headerName: 'ID', 
        width: 90 },
    {
        field: 'product',
        headerName: 'Product Name',
        width: 275,
        editable: true,
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 150,
        editable: true,
    },
    {
        field: 'rating',
        headerName: 'Rating',
        width: 80,
        editable: true,
    },
    {
        field: 'marketplace',
        headerName: 'Marketplace',
        width: 150,
        editable: true,
    },
    {
        field: 'positive',
        headerName: 'Positive',
        width: 150,
        editable: true,
    },
    {
        field: 'negative',
        headerName: 'Negative',
        width: 150,
        editable: true,
    },
    {
        field: 'review',
        headerName: 'Review',
        width: 600,
        editable: true,
    },
    {
        field: 'vine',
        headerName: 'Vine',
        width: 150,
        editable: true,
    },
]

