import "./compare.scss"
import "../../../node_modules/materialize-css/sass/materialize.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import 'semantic-ui-css/semantic.min.css'
import {products} from "../../components/compareProducts/products"
import ProductComparison from "../../components/compareProducts/ProductComparison"
import { initializeApp } from 'firebase/app';

import { getAuth, signInWithRedirect, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { collection, doc, addDoc, getDoc, getDocs, setDoc, onSnapshot} from "firebase/firestore"; 
// import { createCheckoutSession } from 'components/stripe/createCheckoutSession';

const firebaseConfig = {
  apiKey: "AIzaSyBgBYcGBgX4LRH23dP3hTg7Qv9xoSl938M",
  authDomain: "reviews-dd628.firebaseapp.com",
  databaseURL: "https://reviews-dd628-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "reviews-dd628",
  storageBucket: "reviews-dd628.appspot.com",
  messagingSenderId: "1076523572946",
  appId: "1:1076523572946:web:0fd3b378da56bf2873970b",
  measurementId: "G-TR82RMTEEZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
/** firebase.analytics(); */
const db = getFirestore(app);
const auth = getAuth(app);

onAuthStateChanged(auth, async (user) => {
    if (!user){
        //window.location.assign("https://reviewscout.ai/")
        //alert("Not logged in")
    }
  });
  
const Compare = () => {
    
    return (
        
        <div className="list">
            <Sidebar/>
            
            <div className="listContainer">
            <Navbar/>
            <div class="container">
        <div id="div-container">

          

          

             
          
            
            <div id="rootCompare">
            
          
                <div className="widgets"/>
                
                <ProductComparison products={products}/>
                </div>
                </div>
                </div>
                
            </div>
        </div>
    )
}

export default Compare