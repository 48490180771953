import "./home.scss"
import "../../../node_modules/materialize-css/sass/materialize.scss"
import Widget from "../../components/widget/Widget"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import ProductSearch from "../../components/productsearch/ProductSearch"
import ProductSelect from "../../components/productselect/ProductSelect"
import Featured from "../../components/featured/Featured"
import ChartAlt from "../../components/chart/ChartAlt"
import ChartNegative from "../../components/chart/chartNegative"
import ChartPositive from "../../components/chart/chartPositive"
import ReviewFilter from "../../components/reviewfilter/ReviewFilter"
import MarketplaceSelection from "../../components/marketplaceselection/MarketplaceSelection"
import ChartVolume from "../../components/chart/chartVolume"
import { initializeApp } from 'firebase/app';
import React, { Component, useState, useEffect } from 'react';
import Cookies from "js-cookie"
import { getAuth, signInWithRedirect, GoogleAuthProvider, onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { collection, doc, addDoc, getDoc, getDocs, setDoc, onSnapshot} from "firebase/firestore";
 
// import { createCheckoutSession } from 'components/stripe/createCheckoutSession';

const firebaseConfig = {
  apiKey: "AIzaSyBgBYcGBgX4LRH23dP3hTg7Qv9xoSl938M",
  authDomain: "reviews-dd628.firebaseapp.com",
  databaseURL: "https://reviews-dd628-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "reviews-dd628",
  storageBucket: "reviews-dd628.appspot.com",
  messagingSenderId: "1076523572946",
  appId: "1:1076523572946:web:0fd3b378da56bf2873970b",
  measurementId: "G-TR82RMTEEZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
/** firebase.analytics(); */
const db = getFirestore(app);
const auth = getAuth(app);


function getCookie(name) {
    const cookieValue = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)');
    return cookieValue ? cookieValue.pop() : '';
  }


const Home = () => {
    const [isLoading, setLoading] = useState(true)

    const getUID = async () => {


    onAuthStateChanged(auth, async (user) => {
        if (!user){
           
            try{
                var idToken = Cookies.get('cookieToken');
                console.log(idToken)
                signInWithCustomToken(auth, idToken)
                .then((userCredential) => {
                    console.log("Custom Token Authenticated")
                    const user = userCredential.user;
                    console.log(user.uid)
                })
            }
            catch{
                alert("Not logged in")
                window.location.assign("https://reviewscout.ai/")
            }

            
        } else {
            

            const decodedToken = await auth.currentUser?.getIdTokenResult();
            if (decodedToken.claims.stripeRole == 'free' || 'premium'){
                //console.log(user.uid)
            const loggedInUID = user.uid
            //Cookies.get('UIDValue') = loggedInUID
            Cookies.set('UIDValue', loggedInUID)
            //console.log(Cookies.get('UIDValue'))
            console.log("firebasefetch")
            await fetch('https://flask-backend-7hpag3baeq-nw.a.run.app/firebaseuid',{
            method:'POST',
            'credentials': 'include',
            headers:{
                'Content-Type':'application/json',
                
            },
            body: JSON.stringify({uid: loggedInUID})})
            setLoading(false)
            }
            else{
                alert("Subscription not set up. Please subscribe on the home page")
                //window.location.assign("https://youtube.com")
            }
            
        
        
    }
    });
    }

useEffect(() => {
    getUID();
    
}, [])

if (isLoading) {
    return <div className="Loading">Loading... This may take some time.</div>
  }
    return (
        
        <div className="home">
            <Sidebar/>
            <Navbar/>
            <div className="container">
            <div className="divContainer">
                <ProductSearch/>
            <div className="widgets">
                <ProductSelect/>
                <ReviewFilter/>
                <MarketplaceSelection/>
            </div>
            <div className="widgets">
            <Widget type="totalreviews"/>
            <Widget type="avgreviews"/>
            <Widget type="posnegcomments"/>
            </div>
            <div className="chartsClassOverall">
                <Featured/>
                <ChartAlt/>
                </div>
                <div className="posNegCharts">
                <ChartPositive/>
                
                </div>
                <div className="posNegCharts">
                <ChartNegative/>
                </div>
                <div className="chartsClassOverall">
                <ChartVolume/>   
                </div>
                
            </div>
    </div>
    
        </div>
    )
}

export default Home