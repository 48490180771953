import { Item, Button } from "semantic-ui-react";


const ProductCard = ({
  product,
  addToCompare,
  removeFromCompare,
  selected
}) => (
  <Item key={product.name}>
    
    <Item.Content verticalAlign="middle">
      <Item.Header>{product.name}</Item.Header>
      <Item.Description>Ranking: {product.ranking}</Item.Description>
      <Item.Description>Average Rating: {product.average_rating}</Item.Description>
      <Item.Description>Total Reviews: {product.total_reviews}</Item.Description>
      <Item.Description>Positive Comments: {product.positive_comments}</Item.Description>
      <Item.Description>Negative Comments: {product.negative_comments}</Item.Description>
      <Item.Description>Non-verified Purchases: {product.non_verified_purchases}</Item.Description>
      <Item.Description>Verified Purchases: {product.verified_purchases}</Item.Description>
      <Item.Description>Vine Reviews: {product.vine_reviews}</Item.Description>
      
      <Item.Extra>
        {selected && selected.includes(product) ? (
          <Button color="red" onClick={() => removeFromCompare(product)}>
            Remove
          </Button>
        ) : (
          <Button color="blue" onClick={() => addToCompare(product)}>
            Compare
          </Button>
        )}
      </Item.Extra>
    </Item.Content>
  </Item>
);

export default ProductCard;
