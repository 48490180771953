import Sidebar from "../../components/sidebar/Sidebar"
import "./single.scss"
import "../../../node_modules/materialize-css/sass/materialize.scss"
import Navbar from "../../components/navbar/Navbar"
import ChartKeywordPositive from "../../components/chart/chartKeywordPositive"
import SingleDataTable from "./SingleDataTable"
import { useEffect, useState } from "react"
import ChartKeywordNegative from "../../components/chart/chartKeywordNegative"
import { Button } from "@mui/material"
import ChartVolume from "../../components/chart/chartVolume"
import { initializeApp } from 'firebase/app';
import { Tooltip } from 'react-tooltip'
import InfoIcon from '@mui/icons-material/Info';
import { getAuth, signInWithRedirect, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import Cookies from 'js-cookie'
import { collection, doc, addDoc, getDoc, getDocs, setDoc, onSnapshot} from "firebase/firestore"; 
import ChartVolumeIndividual from "../../components/chart/chartVolumeIndividual"
// import { createCheckoutSession } from 'components/stripe/createCheckoutSession';

const firebaseConfig = {
  apiKey: "AIzaSyBgBYcGBgX4LRH23dP3hTg7Qv9xoSl938M",
  authDomain: "reviews-dd628.firebaseapp.com",
  databaseURL: "https://reviews-dd628-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "reviews-dd628",
  storageBucket: "reviews-dd628.appspot.com",
  messagingSenderId: "1076523572946",
  appId: "1:1076523572946:web:0fd3b378da56bf2873970b",
  measurementId: "G-TR82RMTEEZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
/** firebase.analytics(); */
const db = getFirestore(app);
const auth = getAuth(app);
var specifickeywordname = 'Keyword'
onAuthStateChanged(auth, async (user) => {
    if (!user){
        //window.location.assign("https://reviewscout.ai/")
        //alert("Not logged in")
    }
  });


const Single = () => {
    const [showNegative, setShowNegative] = useState(false);
    const [showPositive, setShowPositive] = useState(true);
    const [isLoading, setLoading] = useState(true);
    
    const toggleViews = () => {
        setShowNegative(!showNegative);
        setShowPositive(!showPositive);
    }

    const dataFetch = async () => {
        const specifickeywordSnapshot = doc(db, "reviewdata", Cookies.get('UIDValue'));
        const specificKeywordSnap = await getDoc(specifickeywordSnapshot)

        if (specificKeywordSnap.exists()) {
            specifickeywordname = specificKeywordSnap.data().specifickeyword
            console.log(specifickeywordname)
            setLoading(false)
            document.getElementsByClassName('nameTitleSingle')[0].innerHTML = {specifickeywordname};
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
    }

    useEffect(() => {
        dataFetch();
        
    })

    if (isLoading) {
        return <div className="Loading">Loading... This may take some time.</div>
      }

    return (
        <div className="single">
            <Sidebar/>
            <div className="singleContainer">
                <Navbar/>
                <div class="container">
                <div className="topSingle">
                    
                    <div className="leftSingle">
                    <a data-tooltip-id="individualinfo-tooltip" data-tooltip-content="Individual Keyword - Find out information about the keyword you selected."><InfoIcon/></a>
                    <Tooltip id="individualinfo-tooltip"/>
                        <h1 className="titleSingle">Info</h1>
                        <div className="itemSingle">
                            <div className="detailsSingle">
                                <h1 classname="nameTitleSingle">Keyword Name: {specifickeywordname}</h1>
                                <p classname="descriptionSingle">Here is data about the keyword you selected. From here, you can choose between different charts and see a table below with all the data about the keyword.</p>
                                
                                
                              
                            </div> 
                            
                            
                        </div>
                    </div>
                    <div className="rightSingle">
                    <a data-tooltip-id="individualchart-tooltip" data-tooltip-content="Individual Keyword Chart - Click the button below to switch between a positive and negative chart"><InfoIcon/></a>
                    <Tooltip id="individualchart-tooltip"/>
                        <div className="chart">
                        <Button variant="contained" onClick={() => toggleViews()}>Toggle between positive and negative</Button>
                        {showNegative && <ChartKeywordNegative/>}
                        {showPositive && <ChartKeywordPositive/>}
                        </div>
                    </div>
                </div>
                <div className="bottomSingle">
                    <div class = "dataTableWidget">
                        <SingleDataTable/>
                    </div>
                </div>
                <ChartVolumeIndividual/>
            </div>
            </div>

        </div>
    )
}

export default Single