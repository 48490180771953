export const products = [
    {
      id: "1",
      name: "Cherry",
      image: "https://www.canr.msu.edu/uploads/236/58624/cherries.jpg",
      price: "$1.99",
      colors: ["red", "green", "blue"],
      condition: "Fresh",
      description: "Two Cherries",
      vendors: ["Fresh Fruits Market", "Fruit Ninja"]
    },
    {
      id: "2",
      name: "Orange",
      image: "https://www.canr.msu.edu/uploads/236/58624/cherries.jpg",
      price: "$2.99",
      colors: ["green", "blue"],
      condition: "Frozen",
      description: "Giant Orange",
      vendors: ["Families Market"]
    },
    {
      id: "3",
      name: "Nuts",
      image:
        "https://images-na.ssl-images-amazon.com/images/I/71Y0nx6ayfL._SX425_.jpg",
      price: "$1.00",
      colors: ["red"],
      condition: "Frozen",
      description: "Mixed Nuts",
      vendors: ["Amazon Distributor"]
    },
    {
      id: "4",
      name: "Strawberry",
      image:
        "https://5.imimg.com/data5/CK/LM/MY-46960546/fresh-red-strawberry-500x500.jpg",
      price: "$1.49",
      colors: ["blue"],
      condition: "Fresh",
      description: "Just Strawberry",
      vendors: ["Big Basket"]
    }
  ];
  